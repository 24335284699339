import styled from "styled-components";
import { useSeller } from "../../../context/seller";
import { format } from "date-fns";

export default function Info() {
    const {sellerInfo} = useSeller()
    return (
        <Main>
            <div>
                <h4>Seller ID: {sellerInfo.id}</h4>
            </div>
            <div>
                <h4>Email: {sellerInfo.email}</h4>
            </div>
            <div>
                <h4>Joined at: {sellerInfo.created_at && format(new Date(sellerInfo.created_at), 'dd MMM yyyy')}</h4>
            </div>
            <div>
                <h4>Aprox. Sell Commission: {sellerInfo.sell_commission}% of sale</h4>
            </div>
        </Main>
    )
}

const Main = styled.div`
width: 100%;
padding: 10px;
box-shadow: 0 0 10px rgba(0,0,0,0.1);
> div {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 10px;
    background-color: #EEE;
    border: 1px solid #BBB;
    border-radius: 5px;
    &:last-child {
        margin: 0;
    }
    h4 {
        font-size: 14px;
        font-weight: 400;
    }
}
`