import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import GeneralInput from "../../common/GeneralInput";
import Modal from "../../common/Modal";
import AdminService from "../../../services/admin.service";
import { useAdmin } from "../../../context/admin";
import { Zone } from "../../../types/common.types";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Checkbox from "../../common/Checkbox";
import jsonToFormData from "../../../utils/jsonToFormData";
import FileInput from "../../common/FileInput";

export default function EditZone() {
    const { editZoneId, setEditZoneId } = useAdmin()
    const [data, setData] = useState<Zone>({} as Zone)

    useEffect(() => {
        async function getInfo() {
            try {
                const list: Zone[] = (await AdminService.listZones()).data
                const zone = list.find(z => z.id === editZoneId)
                if (!zone) return
                setData({...zone , new_id: zone.id})
            } catch (error) {
                console.log(error);
            }
        }
        getInfo()
    }, [])

    async function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const newData: Partial<Zone> = {
                id: data.id,
                new_id: data.new_id,
                navbar: data.navbar,
                cover: data.cover,
                overview: data.overview,
                overview_es: data.overview_es,
                overview_pt: data.overview_pt
            }
            const formData = jsonToFormData(newData)
            await AdminService.editZone(formData)
            window.location.reload()
        } catch (error) {
            console.log(error);
        }
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setData(prevData  => ({...prevData, [name]: value }))
    }

    return (
        <Modal
        onSubmit={submit}
        >
            <div className="input-container">
                <Checkbox
                checked={data.navbar}
                label="Show in navbar"
                onChange={(checked: boolean) => setData(prevData => ({...prevData, navbar: checked}))}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                name="new_id"
                label="New ID"
                value={data?.new_id}
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <FileInput
                value={data.cover}
                style={{height: '100px'}}
                label="Cover"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, cover: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { cover, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest as Zone; // Return the rest of the properties without 'photo'
                })}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Overview (en)"
                value={data.overview || ''}
                name="overview"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (es)"
                value={data.overview_es || ''}
                name="overview_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (pt)"
                value={data.overview_pt || ''}
                name="overview_pt"
                onChange={onChange}
                />
            </div>
            <div className="actions"
            style={{marginTop: '20px'}}
            >
                <button type="submit">Submit</button>
            </div>
            <div className="close" title="close"
            onClick={() => setEditZoneId(undefined)}
            >
                <AiOutlineCloseCircle/>
            </div>
        </Modal>
    )
}