import { useEffect, useState } from "react";
import styled from "styled-components";
import AdminService from "../../services/admin.service";
import * as CommonTypes from "../../types/common.types"
import avatar from '../../assets/common/avatar.svg'
import { format } from "date-fns";

export default function Me() {
    const [me, setMe] = useState<CommonTypes.Admin & CommonTypes.Agency & CommonTypes.Owner>({} as any)
    useEffect(() => {
        async function getMe() {
            try {
                const response = await (await AdminService.me()).data
                setMe(response)
            } catch (error) {
                console.log(error)
            }
        }
        getMe()
    }, [])
    return (
        <Main>
            <Content>
                <div className="pic">
                    <img src={avatar} alt=""/>
                </div>
                <h2>{me.name}</h2>
                {
                    me.agency_id ?
                    <h3>{me.agency_name} | {me.source}</h3>
                    :
                    <h3>{me.source}</h3>
                }
                <h4>ID</h4>
                <h5>{me.id}</h5>
                <h4>Email</h4>
                <h5>{me.email}</h5>
                <h4>Joined at</h4>
                <h5>{me.created_at && format(new Date(me.created_at), 'yyyy/MM/dd h:mm')}</h5>
            </Content>
        </Main>
    )
}

const Main = styled.div`
width: 100%;
`

const Content = styled.div`
margin: 15px auto;
width: 80%;
padding: 15px;
border-radius: 10px;
box-shadow: 0 0 5px rgba(0,0,0,0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
.pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background-color: #FA8072;
    img {
        height: 60%;
        width: 60%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
h2 {
    font-size: 18px;
    font-weight: 600;
}
h3 {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}
h4 {
    font-size: 14px;
    font-weight: 400;
    color: #777;
    align-self: flex-start;
}
h5 {
    font-size: 14px;
    font-weight: 400;
    align-self: flex-start;
}
`