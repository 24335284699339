import * as CommonTypes from "../types/common.types";
import http from "./http-common";

class AdminServiceClass {
    private token: string | undefined;
    private config: { headers: { Authorization: string | undefined } };
    private super: string = '/superowner'
    private agency: string = '/agency'
    private seller: string = '/seller'
    private admin: string = '/admin'
    private passenger: string = '/passenger'
    private tours: string = '/tours'
    private trips: string = '/trips'
    private guider: string = '/guider'
    private driver: string = '/driver'
    private reservation: string = '/reservation'
    private payment: string = '/payments'
    private zone: string = '/zone'

    constructor(token?: string) {
        this.token = token;
        this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }
    
    setToken(token: string) {
      this.token = token;
      this.config = { headers: { Authorization: `Bearer ${this.token}` } };
    }

    login(data: CommonTypes.DefaultLogin) {
      return http.post(this.super + '/login', data)
    }

    createTour(data: Partial<CommonTypes.Tour>) {
      return http.post(this.tours + '/create', data, this.config)
    }

    createTrip(data: Partial<CommonTypes.Trip>) {
      return http.post(this.trips, data, this.config)
    }

    editTrip(trip_id: string, data: CommonTypes.Trip) {
      return http.put(this.trips + `/editTrip/${trip_id}`, data, this.config)
    }

    deleteTrip(trip_id: string) {
      return http.delete(this.trips + `/delete/${trip_id}`, this.config)
    }

    me() {
      return http.get(this.super + '/me', this.config)
    }

    getTour(id: string) {
      return http.get(this.tours + `/tour/${id}`, this.config)
    }

    editTour(data: CommonTypes.EditTour) {
      return http.put(this.tours + `/edit`, data, this.config)
    }

    deleteTour(id: string) {
      return http.delete(this.tours + `/delete/${id}`, this.config)
    }

    getPassengerPlan(id: string) {
      return http.get(this.passenger + `/passenger-plan?passenger_id=${id}`, this.config)
    }

    helthByDay(seller_id: string, day: Date, endDay?: Date) {
      return http.post(this.seller + '/healthByDay', {seller_id, day, endDay}, this.config)
    }

    deleteReservation(id: string) {
      return http.delete(this.reservation + `/delete/${id}`, this.config)
    }

    traspass(data: CommonTypes.TraspassReservations) {
      return http.post(this.reservation + '/traspass', data, this.config)
    }

    listTraspass() {
      return http.get(this.reservation + '/listTraspass', this.config)
    }

    getTraspass(agency_id: string) {
      return http.get(this.reservation + `/traspass/${agency_id}`, this.config)
    }

    createAgency(data: Partial<CommonTypes.Agency>) {
      return http.post(this.agency + '/register', data, this.config)
    }

    editAgency(data: Partial<CommonTypes.Agency>) {
      return http.put(this.agency + '/edit', data, this.config)
    }

    deleteAgency(id: string) {
      return http.delete(this.agency + `/delete/${id}`, this.config)
    }

    createSeller(data: Partial<CommonTypes.Seller>) {
      return http.post(this.seller + '/register', data, this.config)
    }

    editSeller(data: Partial<CommonTypes.Seller>) {
      return http.put(this.seller + '/edit', data, this.config)
    }

    deleteSeller(id: string) {
      return http.delete(this.seller + `/delete/${id}`, this.config)
    }

    createPassenger(data: Partial<CommonTypes.Passenger>) {
      return http.post(this.passenger + '/create', data, this.config)
    }

    editPassenger(data: Partial<CommonTypes.Passenger>) {
      return http.put(this.passenger + '/edit/admin', data, this.config)
    }

    deletePassenger(id: string) {
      return http.delete(this.passenger + `/delete/${id}`, this.config)
    }

    createAdmin(data: Partial<CommonTypes.Admin>) {
      return http.post(this.admin + '/register', data, this.config)
    }

    editAdmin(data: Partial<CommonTypes.Admin>) {
      return http.put(this.admin + '/edit', data, this.config)
    }

    deleteAdmin(id: string) {
      return http.delete(this.admin + `/delete/${id}`, this.config)
    }

    createGuider(data: Partial<CommonTypes.Guider>) {
      return http.post(this.guider + '/create', data, this.config)
    }

    editGuider(data: Partial<CommonTypes.Guider>) {
      return http.put(this.guider + '/edit', data, this.config)
    }

    deleteGuider(id: string) {
      return http.delete(this.guider + `/delete/${id}`, this.config)
    }

    createDriver(data: Partial<CommonTypes.Driver>) {
      return http.post(this.driver + '/create', data, this.config)
    }

    editDriver(data: Partial<CommonTypes.Driver>) {
      return http.put(this.driver + '/edit', data, this.config)
    }

    deleteDriver(id: string) {
      return http.delete(this.driver + `/delete/${id}`, this.config)
    }

    listToursReservationsPassengers(date: Date) {
      return http.post(this.tours + '/listToursReservationsPassengers', {date}, this.config)
    }

    listToursTrips(date: string) {
      return http.post(this.tours + '/listToursTripsPassengers', {date}, this.config)
    }

    listEarnings(day: Date, endDay?: Date) {
      return http.post(this.trips + '/list/earnings', {day, endDay}, this.config)
    }

    listToursAssigned(date: string) {
      return http.post(this.tours + '/listToursTripsAssigned', {date}, this.config)
    }
    
    listTripsByDay(date: Date, tour_id: string) {
      return http.post(this.trips + '/listTripsByDay', {date, tour_id}, this.config)
    }

    listTripsByDayTraspass(date: Date, tour_id: string) {
      return http.post(this.trips + '/listTripsByDayTraspass', {date, tour_id}, this.config)
    }

    listTripsByDayReservations(date: Date) {
      return http.post(this.trips + '/listTripsByDayReservations', {date}, this.config)
    }

    updateCosts(costs: CommonTypes.TripCost[], trip_id: string) {
      return http.put(this.trips + '/costs', {costs, trip_id}, this.config)
    }

    updatePassStatus(status: boolean, reservation_id: string) {
      return http.put(this.reservation + '/passStatus', {status, reservation_id}, this.config)
    }

    updateArriveStatus(status: boolean, reservation_id: string) {
      return http.put(this.reservation + '/arriveStatus', {status, reservation_id}, this.config)
    }

    traspassAddPassengers(trip_id: string, agency: string, passengers: CommonTypes.Passenger[]) {
      return http.post(this.trips + '/traspass/addPassengers', {trip_id, agency, passengers}, this.config)
    }

    traspassCreateToken(trip_id: string, agency: string) {
      return http.post(this.trips + '/traspass/createToken', {trip_id, agency}, this.config)
    }

    traspassAddPassengersToken(token_id: string, passengers: CommonTypes.Passenger[]) {
      return http.post(this.trips + '/traspass/addPassengersToken', {token_id, passengers})
    }

    assignTrip(reservation_ids: string[], trip_id: string, traspass_price?: number) {
      return http.post(this.reservation + '/assignTrip', {reservation_ids, trip_id, traspass_price}, this.config)
    }

    listSales(seller_id: string) {
      return http.post(this.seller + '/listSales', {seller_id}, this.config)
    }

    updateSales(authorized: boolean, amount: number, sale_id: string) {
      return http.put(this.seller + '/updateSales', {authorized, amount, sale_id}, this.config)
    }

    deleteSale(sale_id: string) {
      return http.delete(this.seller + `/deleteSale/${sale_id}`, this.config)
    }

    getTrip(trip_id: string) {
      return http.get(this.trips + `/trip?trip_id=${trip_id}`, this.config)
    }

    closeTrip(trip_id: string) {
      return http.put(this.trips + '/close', { trip_id }, this.config)
    }

    listAdmins() {
      return http.get(this.admin + '/list', this.config)
    }

    listSellers() {
      return http.get(this.seller + '/list', this.config)
    }

    listAgencies() {
      return http.get(this.agency + '/list', this.config)
    }

    listPassengers() {
      return http.get(this.passenger, this.config)
    }

    listGuiders() {
      return http.get(this.guider, this.config)
    }

    listDrivers() {
      return http.get(this.driver, this.config)
    }

    getReservation(reservation_id: string) {
      return http.get(this.reservation + `/reservation/${reservation_id}`, this.config)
    }

    editReservation(reservation_id: string, data: CommonTypes.Reservation) {
      return http.put(this.reservation + `/edit/${reservation_id}`, data, this.config)
    }

    registerPayment(data: FormData) {
      return http.post(this.payment + '/add', data, {...this.config, headers: {...this.config.headers, 'Content-Type': 'multipart/form-data' }})
    }

    listPayments(agency_id: string, day: Date, endDay?: Date) {
      return http.post(this.payment + '/list', {agency_id, day, endDay}, this.config)
    }

    getTourInfo(data: Partial<CommonTypes.TourInfo>) {
      return http.post(this.tours + '/info', data, this.config)
    }
    
    handleTourInfo(data: FormData) {
      return http.put(this.tours + '/handle/info', data, {...this.config, headers: {...this.config.headers, 'Content-Type': 'multipart/form-data' }})
    }
    
    postBlog(data: FormData) {
      return http.post(this.tours + '/blog', data, {...this.config, headers: {...this.config.headers, 'Content-Type': 'multipart/form-data' }})
    }

    // zones
    registerZone(data: FormData) {
      return http.post(this.zone + '/register', data, {...this.config, headers: {...this.config.headers, 'Content-Type': 'multipart/form-data' }})
    }

    editZone(data: FormData) {
      return http.put(this.zone + '/edit', data, {...this.config, headers: {...this.config.headers, 'Content-Type': 'multipart/form-data' }})
    }

    deleteZone(id: string) {
      return http.delete(this.zone + `/delete/${id}`, this.config)
    }

    listZones() {
      return http.get(this.zone + '/list', this.config)
    }

    assignZone(tour_id: string, zone_id: string) {
      return http.post(this.zone + '/assign', { tour_id, zone_id }, this.config)
    }
}

const AdminService = new AdminServiceClass()

export default AdminService;