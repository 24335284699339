import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSeller } from "../../context/seller";
import format from "date-fns/format";
import * as CommonTypes from "../../types/common.types";
import { countries } from "countries-list";
import { AiOutlineInfoCircle } from "react-icons/ai"
import SellerService from "../../services/seller.service";
import { MdEdit } from "react-icons/md";

export default function Tours() {
    const {currentDay, setEditReservationId, setTripInfo, setCreateReservationTourId} = useSeller()
    const [list, setList] = useState<CommonTypes.FullTourInfo[]>([])
    useEffect(() => {
        async function retrieveList() {
            if (!currentDay) return 
            try {
                const date = format(currentDay, 'yyyy-MM-dd')
                const response = await SellerService.listTours(date)
                setList(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        retrieveList()
    }, [currentDay])
    return (
        <Main>
            {
                list
                .sort((a, b) =>  b.reservations.length - a.reservations.length)
                .map((tour, idx) => {
                    return (
                        <div className="tour" key={idx}>
                            <div className="header">
                                {tour.id}
                            </div>
                            <TableWrapper>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: '150px'}}>Name</th>
                                            <th>Age</th>
                                            <th>Born</th>
                                            <th>Depart</th>
                                            <th>Price</th>
                                            <th>Ticket</th>
                                            <th>Seller</th>
                                            <th style={{width: '40px'}}
                                            ><button
                                            onClick={() => setCreateReservationTourId(tour.id)}
                                            >+</button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tour.reservations?.map((reservation, idx) => {
                                            return (
                                                    <tr key={idx}>
                                                        <td>{reservation.passenger.name}</td>
                                                        <td>{reservation.passenger.age} y/o</td>
                                                        <td>{countries[reservation.passenger.nationality as keyof typeof countries].name}</td>
                                                        <td>{reservation.trip?.trip_time}</td>
                                                        <td>{reservation.price}</td>
                                                        <td>{reservation.tickets}</td>
                                                        <td>{reservation.passenger.seller?.name}</td>
                                                        <td><button onClick={() => setTripInfo(reservation)}><AiOutlineInfoCircle/></button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
width: 90%;
margin: auto;
margin-top: 15px;
max-height: calc(65vh - 90px);
overflow: hidden;
overflow-y: unset;
.tour {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        
    }
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: auto;
`

const Table = styled.table`
table-layout: fixed;
height: 100%;
width: 100%;
min-width: 500px;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    > button {
        width: 30px;
        height: 30px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.blue};
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    > button {
        border: none;
        background-color: none;
        font-size: 16px;
        cursor: pointer;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`