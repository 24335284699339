import styled from "styled-components";
import AdminService from "../../../services/admin.service";
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Seller } from "../../../types/common.types";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { add, format } from "date-fns";
import CalendarDual from "../../common/CalendarDual";

interface SellersFilterInterface {
    day: Date,
    endDay: Date | undefined,
    setDay: Dispatch<SetStateAction<Date>>,
    setEndDay: Dispatch<SetStateAction<Date | undefined>>,
    setSellerId: Dispatch<SetStateAction<string | undefined>>
}

export default function SellersFilter({day, setDay, setSellerId, endDay, setEndDay}: SellersFilterInterface) {
    const [list, setList] = useState<Seller[]>([])
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
    useEffect(() => {
        async function getSellers() {
            try {
                const response = await (await AdminService.listSellers()).data
                setList(response)
            } catch (error) {
                console.log(error)
            }
        }
        getSellers()
    }, [])

    function addDay(days: number) {
        const newDay = add(day, {days})
        setDay(newDay)
    }
    return (
        <Main>
            <DaySelection>
                <IoIosArrowBack
                onClick={() => addDay(-1)}
                />
                <div>
                    <span onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
                        {day ? `${format(day, 'dd/MM/yyyy')} ${ endDay ? '- ' + format(endDay, 'dd/MM/yyyy') : ''}` : '-'}
                    </span>
                    {
                        isCalendarOpen &&
                        <CalendarDual
                        containerStyle={{position: 'absolute', zIndex: 10}}
                        setSelectedDay={(day: Date) => setDay(day)}
                        selectPastDays
                        selectedDay={day}
                        endDay={endDay}
                        setEndDay={setEndDay}
                        />
                    }
                </div>
                <IoIosArrowForward
                onClick={() => addDay(1)}
                />
            </DaySelection>
            <select
            onChange={e => setSellerId(e.target.value)}
            >
                <option selected disabled
                >-</option>
                {
                    list.map((seller, idx) => {
                        return <option key={idx} value={seller.id}>{seller.name}</option>
                    })
                }
            </select>
        </Main>
    )
}

const Main = styled.div`
margin-bottom: 20px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
gap: 15px;
select {
    padding: 10px 14px;
    outline: none;
}
`

const DaySelection = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
z-index: 15;
> div > span {
    cursor: pointer;
}
> svg {
    cursor: pointer;
}
`