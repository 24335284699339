import styled, {keyframes} from "styled-components";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useEffect, useState } from "react";
import { Driver, Guider, Reservation, TourTrip, TripReservation } from "../../../types/common.types";
import InputDropdown from "../../common/InputDropdown";
import Calendar from "../../common/CalendarInput";
import { format } from "date-fns";
import GeneralInput from "../../common/GeneralInput";
import { useAdmin } from "../../../context/admin";
import AdminService from "../../../services/admin.service";
import Checkbox from "../../common/Checkbox";
import removeTimezone from "../../../utils/removeTimezone";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";

export default function TourInfo() {
    const { tourInfoId, setTourInfoId, setCreateBlogTourId, user, setEditTripId, setEditTourInfoId } = useAdmin()
    const [data, setData] = useState<Partial<TourTrip>>({})
    const [newTrip, setNewTrip] = useState<Partial<TripReservation>>({})
    const [isCreatingTrip, setIsCreatingTrip] = useState(false)
    const [guiders, setGuiders] = useState<Guider[]>([])
    const [drivers, setDrivers] = useState<Driver[]>([])
    useEffect(() => {
        async function getInfo() {
            try {
                if (!tourInfoId) return
                const response = await (await AdminService.getTour(tourInfoId)).data
                setData(response)
                const guidersResponse = (await AdminService.listGuiders()).data
                setGuiders(guidersResponse)
                const driversResponse = (await AdminService.listDrivers()).data
                setDrivers(driversResponse)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [])

    async function submitTrip(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            await AdminService.createTrip({tour_id: tourInfoId, ...newTrip})
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    async function deleteTrip(id: string) {
        try {
            await AdminService.deleteTrip(id)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal>
                <h1>{data.id}</h1>
                <div className="close" title="close"
                onClick={() => setTourInfoId(undefined)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                {
                    user?.user.source !== 'superowners' &&
                    <button className="edit-tour-info"
                    style={{display: 'block', marginBottom: '10px'}}
                    type="button"
                    onClick={() => setEditTourInfoId(tourInfoId)}
                    >
                        <span>
                            <CiEdit/>
                            Edit tour info
                        </span>
                    </button>
                }
                {
                    user?.user.source !== 'superowners' &&
                    <button className="create-trip"
                    onClick={() => setIsCreatingTrip(true)}
                    >
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> 
                            Create Trip
                        </span>
                    </button>
                }
                {
                    user?.user.source !== 'superowners' &&
                    <button className="create-trip"
                    style={{display: 'block', marginTop: '10px'}}
                    onClick={() => setCreateBlogTourId(tourInfoId)}
                    >
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> 
                            Post blog
                        </span>
                    </button>
                }
                {
                    isCreatingTrip &&
                    <NewTrip onSubmit={submitTrip}>
                        <InputDropdown
                        style={{marginBottom: '20px'}}
                        options={guiders}
                        label="Guider ID"
                        optionDefaultHeight={53}
                        onChange={(newValue: string) => setNewTrip(prevData => ({...prevData, guider_id: newValue}))}
                        keys={['id', 'name']}
                        selector="id"
                        />
                        <InputDropdown
                        style={{marginBottom: '20px'}}
                        options={drivers}
                        label="Driver ID"
                        keys={['id', 'name']}
                        selector="id"
                        optionDefaultHeight={53}
                        onChange={(newValue: string) => setNewTrip(prevData => ({...prevData, driver_id: newValue}))}
                        />
                        <GeneralInput
                        value={newTrip.car_patent}
                        onChange={e => setNewTrip(prevData => ({...prevData, car_patent: e.target.value}))}
                        label="Car patent"
                        placeholder="AA150BL"
                        required
                        style={{marginBottom: '20px'}}
                        />
                        <Checkbox
                        checked={newTrip?.pickup || false}
                        color="#33AADD"
                        label="Pickup"
                        containerStyle={{marginBottom: '20px'}}
                        onChange={(newValue: boolean) => setNewTrip(prevData => ({...prevData, pickup: newValue}))}
                        />
                        {
                            !newTrip?.pickup &&
                            <GeneralInput
                            value={newTrip.meeting_point || ''}
                            label="Meeting point"
                            style={{marginBottom: '20px'}}
                            onChange={e => setNewTrip(prevData => ({...prevData, meeting_point: e.target.value}))}
                            />
                        }
                        <div className="calendar">
                            <Calendar
                            selectPastDays
                            selectedDay={newTrip.trip_date}
                            setSelectedDay={(newValue: Date | null) => {setNewTrip(prevData => ({...prevData, trip_date: newValue}))}}
                            />
                            <span>{newTrip.trip_date && format(newTrip.trip_date, 'd MMM yyyy')}</span>
                        </div>
                        <input type="time"
                        required
                        onChange={(e) => setNewTrip(prevData => ({...prevData, trip_time: e.target.value}))}
                        />
                        <GeneralInput
                        type="number"
                        min={1}
                        value={newTrip.max_capacity}
                        onChange={e => setNewTrip(prevData => ({...prevData, max_capacity: parseInt(e.target.value)}))}
                        label="Max capacity"
                        placeholder="1"
                        required
                        style={{marginBottom: '20px'}}
                        />
                        <GeneralInput
                        type="text"
                        value={newTrip.recommendations}
                        onChange={e => setNewTrip(prevData => ({...prevData, recommendations: e.target.value}))}
                        label="Recommendations"
                        style={{marginBottom: '20px'}}
                        />
                        <div className="actions">
                            <div className="cancel"
                            onClick={() => {
                                setIsCreatingTrip(false)
                            }}
                            >
                                Cancel
                            </div>
                            <button className="submit" type="submit">
                                Create
                            </button>
                        </div>
                    </NewTrip>
                }
                <div className="trips">
                    { 
                        data.trips &&
                        data.trips.length ?
                        data.trips?.map((item: TripReservation, idx: number) => {
                            if (!item.trip_date) return <></>
                            const date = removeTimezone(item.trip_date.toString())
                            return (
                                <div className="trip" key={idx}>
                                    <button type="button"
                                    className="edit"
                                    onClick={() => setEditTripId(item.id)}
                                    >
                                        <MdEdit/>
                                    </button>
                                    <button type="button"
                                    className="delete"
                                    onClick={() => deleteTrip(item.id)}
                                    >
                                        <FaTrash/>
                                    </button>
                                    <h3>
                                    {item.trip_date && date}
                                    <span> {item.trip_time}</span>
                                    </h3>
                                    <h4>
                                        Guider: {item.guider_name}
                                    </h4>
                                    <h4>
                                        Driver: {item.driver_name}
                                    </h4>
                                    {
                                        item.reservations?.map((item: Reservation, idx: number) => {
                                            return (
                                                <>
                                                <hr />
                                                <h5>
                                                    {item.passenger_name}
                                                    <br />
                                                    <a href={`mailto:${item.passenger_email}`}>
                                                        {item.passenger_email}
                                                    </a>
                                                </h5>
                                                <hr />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                        :
                        <>No trips upcoming</>
                    }
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }

`

const Modal = styled.div`
width: 80%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 560px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
.trips {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .trip {
        border: 1px solid ${props => props.theme.gray};
        padding: 10px;
        min-width: 200px;
        flex: 1;
        border-radius: 5px;
        position: relative;
        hr {
            margin: 7px 0;
            border-top: 1px solid ${props => props.theme.whiteBackground};
        }
        > button {
            z-index: 10;
            position: absolute;
            border-radius: 10px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in-out 0.2s;
            cursor: pointer;
            font-size: 18px;
            color: ${props => props.theme.white};
        }
        > button.edit {
            border: 2px solid ${props => props.theme.blueLight};
            background-color: ${props => props.theme.blueLight};
            right: 50px;
            top: 10px;
        }
        > button.edit:hover {
            background-color: ${props => props.theme.blue};
        }
        > button.delete {
            background-color: ${props => props.theme.lightRed};
            border: 2px solid ${props => props.theme.lightRed};
            right: 10px;
            top: 10px;
        }
        h3 {
            font-weight: 600;
            font-size: 18px;
            span {
                font-weight: 500;
                font-size: 14px;
            }
        }
        h4 {
            font-size: 14px;
            font-weight: 400;
        }
        h5 {
            font-size: 12px;
            font-weight: 500;
        }
    }
}
.create-trip {
    border-radius: 0.9em;
    padding: 10px 20px 10px 12px;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid ${props => props.theme.yellowDark};
    background-color: ${props => props.theme.yellowDark};
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.white};
        font-weight: 600;
    }
    &:active {
        transform: scale(0.9);
    }
}
.edit-tour-info {
    border-radius: 0.9em;
    padding: 10px 20px 10px 12px;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid ${props => props.theme.yellowDark};
    background-color: ${props => props.theme.yellowDark};
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.white};
        font-weight: 600;
        gap: 5px;
        svg {
            font-size: 20px;
        }
    }
    &:active {
        transform: scale(0.9);
    }
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
`

const NewTrip = styled.form`
width: 95%;
margin: 20px auto;
padding: 15px;
background-color: ${props => props.theme.blackTransparent};
input[type='time'] {
    padding: 5px 10px;
    margin: 20px 0;
    cursor: pointer;
}
.calendar {
    display: flex;
    align-items: center;
    gap: 10px;
}
.actions {
    display: flex;
    gap: 15px;
    button, .cancel {
        display: inline-block;
        width: 6.5em;
        height: 2.6em;
        overflow: hidden;
        font-size: 17px;
        text-align: center;
        z-index: 1;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
    }
    .cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${props => props.theme.black};
    }
    .submit {
        background-color: ${props => props.theme.white};
        color: ${props => props.theme.blue};
        border: 2px solid ${props => props.theme.blue};

    }
    .submit::before {
        position: absolute;
        content: "";
        background: ${props => props.theme.blue};
        width: 170px;
        height: 200px;
        z-index: -1;
        border-radius: 50%;
    }
    
    .submit:hover {
        color: ${props => props.theme.white};
    }
    
    button:before, .cancel:before {
        top: 100%;
        left: 100%;
        transition: .3s all;
    }
    
    button:hover::before, .cancel:hover::before {
        top: -30px;
        left: -30px;
    }
}
`