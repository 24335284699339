import { useEffect, useState } from "react";
import styled from "styled-components";
import { Passenger } from "../../../types/common.types";
import { format } from "date-fns";
import { countries } from "countries-list";
import AdminService from "../../../services/admin.service";
import { useAdmin } from "../../../context/admin";
import { mobile } from "../../../styles/responsive";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";

export default function List() {
    const [list, setList] = useState<Passenger[]>([])
    const {setPassengerPlanId, setEditPassengerData} = useAdmin()
    useEffect(() => {
        async function getPassengers() {
            try {
                const response = await (await AdminService.listPassengers()).data
                setList(response)
            } catch (error) {
                console.log(error)
            }
        }
        getPassengers()
    }, [])
    async function deletePassenger(id: string) {
        try {
            await AdminService.deletePassenger(id)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            {
                list.map((item: Passenger, idx: number) => {
                    return (
                        <div className="card-container">
                            <button type="button"
                                className="edit"
                                onClick={() => setEditPassengerData(item)}
                                >
                                    <MdEdit/>
                            </button>
                            <button type="button"
                                className="delete"
                                onClick={() => deletePassenger(item.id)}
                                >
                                    <FaTrash/>
                            </button>
                            <div className="card" key={idx}
                            onClick={() => setPassengerPlanId(item.id)}
                            >
                                <h3 className="card__title">
                                    {item.name}
                                </h3>
                                <div className="card__content">
                                    Id: {item.id}
                                    <br />
                                    Born: {countries[item.nationality as keyof typeof countries].name}
                                    <br />
                                    Age: {item.age} y/o
                                    <br />
                                    Email: {item.email}
                                    <br />
                                    Phone: {item.phone}
                                    <br />
                                    Hotel: {item.hotel}
                                    <br />
                                    Rut / Passport: {item.rut_passport}
                                    <br />
                                    Remark: {item.remark}
                                    <hr />
                                    {
                                        item.traspassed_from ?
                                        <>
                                        Traspassed from agency: {item.traspassed_from}
                                        </>
                                        :
                                        <>
                                        Seller: {item.seller_name || item.admin_name}
                                        <br />
                                        Email: {item.seller_email || item.admin_email}
                                        <br />
                                        <span className="by-admin">{item.admin_id && 'Created by admin'}</span>
                                        </>
                                    }
                                </div>
                                <div className="card__date">
                                    {format(new Date(item.created_at), 'yyyy MMM dd H:mm')}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: grid;
grid-template-columns: repeat(2, minmax(200px, 1fr));
${mobile({
    gridTemplateColumns: '1fr'
})}
gap: 20px;
hr {
    margin: 10px 0;
    border-top: 1px solid ${props => props.theme.blackTransparent};
}
.card-container {
    position: relative;
    > button {
        z-index: 10;
        position: absolute;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        font-size: 18px;
        color: ${props => props.theme.white};
    }
    > button.edit {
        border: 2px solid ${props => props.theme.blueLight};
        background-color: ${props => props.theme.blueLight};
        right: 50px;
        top: 10px;
    }
    > button.edit:hover {
        background-color: ${props => props.theme.blue};
    }
    > button.delete {
        background-color: ${props => props.theme.lightRed};
        border: 2px solid ${props => props.theme.lightRed};
        right: 10px;
        top: 10px;
    }
}
.card {
    padding: 1rem;
    border-radius: var(--border-radius);
    background: ${props => props.theme.whiteBackground};
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    transition: transform .3s ease;
    cursor: pointer;
    &:hover {
        transform: translateY(-5px);
    }
}

.card > * + * {
    margin-top: 1.1em;
}

.card .card__content {
    color: ${props => props.theme.dark};
    font-size: 0.86rem;
    line-height: 20px;
    span.by-admin {
        font-size: 12px;
        color: ${props => props.theme.gray};
    }
}

.card .card__title {
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.card .card__date {
    color: #6e6b80;
    font-size: 0.8rem;
}
`