import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAdmin } from "../../../context/admin";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { add, format, startOfToday } from "date-fns";
import CalendarDual from "../../common/CalendarDual";
import { PaymentList } from "../../../types/common.types";
import AdminService from "../../../services/admin.service";
import removeTimezone from "../../../utils/removeTimezone";
import formatNumberToString from "../../../utils/formatNumber";

export default function ViewPayments() {
    const {viewPaymentAgencyId, setViewPaymentAgencyId} = useAdmin()
    const [day, setDay] = useState<Date>(startOfToday())
    const [endDay, setEndDay] = useState<Date | undefined >()
    const [type, setType] = useState<string>('all')
    const [payments, setPayments] = useState<PaymentList[]>([])
    
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
    useEffect(() => {
        async function getInfo() {
            try {
                if (!viewPaymentAgencyId) return
                const response = (await AdminService.listPayments(viewPaymentAgencyId, day, endDay)).data
                setPayments(response)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [day, endDay, viewPaymentAgencyId])
    function addDay(days: number) {
        const newDay = add(day, {days})
        setDay(newDay)
    }
    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                    onClick={() => {
                        setViewPaymentAgencyId(undefined)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <DaySelection>
                    <div>
                    <IoIosArrowBack
                    onClick={() => addDay(-1)}
                    />
                    <span onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
                        {day ? `${format(day, 'dd/MM/yyyy')} ${ endDay ? '- ' + format(endDay, 'dd/MM/YYY') : ''}` : '-'}
                    </span>
                    <IoIosArrowForward
                    onClick={() => addDay(1)}
                    />
                    </div>
                        {
                            isCalendarOpen &&
                            <CalendarDual
                            setSelectedDay={(day: Date) => setDay(day)}
                            selectPastDays
                            selectedDay={day}
                            endDay={endDay}
                            setEndDay={setEndDay}
                            />
                        }
                </DaySelection>
                <select
                onChange={e => setType(e.target.value)}
                >
                    <option selected
                    >all</option>
                    <option
                    >received</option>
                    <option
                    >sent</option>
                </select>
                <div className="list">
                    {
                        payments
                        .filter(p => type === 'all' ? true : p.status === type)
                        .map((p, idx) => {
                            return (
                                <div className="payment" key={idx}>
                                <div className="header">
                                    <div className="name">
                                        <span>
                                            {p.status} {removeTimezone(p.created_at.toString())}
                                        </span>
                                    </div>
                                </div>
                                <TableWrapper >
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Amount</th>
                                                <th>Payment Method</th>
                                                <th>Remarks</th>
                                                <th>pic</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${p.amount}</td>
                                                <td>{p.payment_method}</td>
                                                <td>{p.remarks}</td>
                                                <td>
                                                    <div className="img">
                                                        <img crossOrigin="anonymous" src={`${process.env.REACT_APP_API_URL}/files/${p.pic}`} alt="" />
                                                        <a target="_BLANK" href={`${process.env.REACT_APP_API_URL}/files/${p.pic}`}></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
h3 {
    font-size: 16px;
    font-weight: 400;
}
hr {
    margin: 20px auto;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
select {
    margin: 10px auto;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    border: 1px solid ${props => props.theme.gray};
    padding: 10px 14px;
    outline: none;
}
.payment {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    margin-bottom: 20px;
    .header {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        border-radius: 10px 10px 0 0;
        height: 40px;
        gap: 10px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        .name {
            flex: 1;
            text-align: center;
            display: flex;
            gap: 10px;
            justify-content: center;
            text-transform: capitalize;
        }
    }
}
`

const DaySelection = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10px;
z-index: 15;
> div > span {
    cursor: pointer;
}
> svg {
    cursor: pointer;
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
overflow-x: unset;
`

const Table = styled.table`
border-collapse: collapse;
table-layout: fixed;
height: 100%;
position: relative;
width: 100%;
min-width: 400px;
overflow: hidden;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
td {
    text-align: center;
    height: 100px;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: none;
        background-color: ${props => props.theme.blueLight};
        color: ${props => props.theme.white};
        cursor: pointer;
    }
    .img {
        width: 100%;
        position: relative;
        img {
            width: 100%;
        }
        a {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
        }
        padding: 10px;
    }
}
tr {
    height: 40px;
    width: 100%;
}
`