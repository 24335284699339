import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSeller } from "../../../context/seller";
import { ChangeEvent, useEffect, useState } from "react";
import * as CommonTypes from '../../../types/common.types'
import SellerService from "../../../services/seller.service";
import { countries } from "countries-list";
import { format } from "date-fns";
import GeneralInput from "../../common/GeneralInput";

export default function Passengers() {
    const {setIsPassengersModalOpen} = useSeller()
    const [initialList, setInitialList] = useState<CommonTypes.Passenger[]>([])
    const [list, setList] = useState<CommonTypes.Passenger[]>(initialList)
    useEffect(() => {
        async function retrieveList () {
            try {
                const response = await SellerService.listPassengers()
                setInitialList(response.data)
                setList(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        retrieveList()
    }, [])

    function onChangeValue(e: ChangeEvent<HTMLInputElement>) {
        const newList = initialList.filter((item) => item.email.toLowerCase().includes(e.target.value.toLowerCase()))
        setList(newList)
    }
    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                    onClick={() => setIsPassengersModalOpen(false)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <h3>Passengers</h3>
                <GeneralInput
                onChange={onChangeValue}
                placeholder="Filter by email"
                style={{marginTop: '20px'}}
                />
                {
                    list.map((passenger, idx) => {
                        return (
                            <div className="card" key={idx}
                            >
                                <h3 className="card__title">
                                    {passenger.name}
                                </h3>
                                <div className="card__content">
                                    Id: {passenger.id}
                                    <br />
                                    Born: {countries[passenger.nationality as keyof typeof countries].name}
                                    <br />
                                    Age: {passenger.age} y/o
                                    <br />
                                    Email: {passenger.email}
                                    <br />
                                    Phone: {passenger.phone}
                                    <br />
                                    Hotel: {passenger.hotel}
                                    <br />
                                    Rut / Passport: {passenger.rut_passport}
                                    <br />
                                    Remark: {passenger.remark}
                                    <hr />
                                    Seller: {passenger.seller_name || passenger.admin_name}
                                    <br />
                                    Email: {passenger.seller_email || passenger.admin_email}
                                    <br />
                                    <span className="by-admin">{passenger.admin_id && 'Created by admin'}</span>
                                </div>
                                <div className="card__date">
                                    {format(new Date(passenger.created_at), 'yyyy MMM dd H:mm')}
                                </div>
                            </div>
                        )
                    })
                }
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.form`
width: 70%;
max-height: 75%;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.card {
    --border-radius: 0.75rem;
    font-family: "Arial";
    padding: 1rem;
    border-radius: var(--border-radius);
    background: ${props => props.theme.whiteBackground};
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    position: relative;
    margin: 20px 0;
}

.card > * + * {
    margin-top: 1.1em;
}

.card .card__content {
    color: ${props => props.theme.dark};
    font-size: 0.86rem;
    line-height: 20px;
    span.by-admin {
        font-size: 12px;
        color: ${props => props.theme.gray};
    }
}

.card .card__title {
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.card .card__date {
    color: #6e6b80;
    font-size: 0.8rem;
}

`