import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAdmin } from "../../../context/admin";
import { useGlobal } from "../../../context/global";
import Modal from "../../common/Modal";
import GeneralInput from "../../common/GeneralInput";
import { ChangeEvent, FormEvent, useState } from "react";
import { Blog } from "../../../types/common.types";
import FileInput from "../../common/FileInput";
import AdminService from "../../../services/admin.service";
import jsonToFormData from "../../../utils/jsonToFormData";

export default function PostBlog() {
    const { createBlogTourId, setCreateBlogTourId} = useAdmin()
    const [data, setData] = useState<Blog>({tour_id: createBlogTourId} as Blog)

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name
        const value = e.target.value

        setData(prevData => ({...prevData, [name]: value}))
    }

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const formData = jsonToFormData(data)
            await AdminService.postBlog(formData)
            window.location.reload()
        } catch (error) {
            console.log(error);
            
        }
    }

    return (
        <Modal
        onSubmit={onSubmit}
        >
            <div className="close" title="close"
                onClick={() => setCreateBlogTourId(undefined)}
                >
                    <AiOutlineCloseCircle/>
            </div>
            <h1>Create blog</h1>
            <div className="input-container">
                <GeneralInput
                label="Title (en)"
                name="title"
                value={data.title}
                onChange={onChange}
                />
                <GeneralInput
                label="Title (es)"
                name="title_es"
                value={data.title_es}
                onChange={onChange}
                />
                <GeneralInput
                label="Title (pt)"
                name="title_pt"
                value={data.title_pt}
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Overview (en)"
                name="overview"
                value={data.overview}
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (es)"
                name="overview_es"
                value={data.overview_es}
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (pt)"
                name="overview_pt"
                value={data.overview_pt}
                onChange={onChange}
                />
                
            </div>
            <div className="input-container">
                <GeneralInput
                label="Description (en)"
                name="description"
                value={data.description}
                onChange={onChange}
                />
                <GeneralInput
                label="Description (es)"
                name="description_es"
                value={data.description_es}
                onChange={onChange}
                />
                <GeneralInput
                label="Description (pt)"
                name="description_pt"
                value={data.description_pt}
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <FileInput
                    value={data.cover}
                    style={{height: '100px'}}
                    label="Cover"
                    accept=".png, .jpg, .jpeg, .heic"
                    onChange={(newFile: File) => setData(prevData => ({...prevData, cover: newFile}))}
                    deleteFile={() => setData(prevData => {
                        const { cover, ...rest } = prevData; // Destructure to exclude 'photo'
                        return rest as Blog; // Return the rest of the properties without 'photo'
                    })}
                    />
            </div>
            <div className="actions">
                <button>
                    Create
                </button>
            </div>
        </Modal>
    )
}