import styled, {keyframes} from "styled-components";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useSeller } from "../../../context/seller";
import { ChangeEvent, useEffect, useState } from "react";
import * as CommonTypes from '../../../types/common.types'
import SellerService from "../../../services/seller.service";
import InputDropdown from "../../common/InputDropdown";
import Calendar from "../../common/CalendarInput";
import { format } from "date-fns";
import GeneralInput from "../../common/GeneralInput";
import dollar from '../../../assets/common/dollar.svg'
import Dropdown from "../../common/Dropdown";
import { FaTrash } from "react-icons/fa";
import insert from "../../../utils/insert";

export default function CreateReservation () {
    const {createReservationTourId, setCreateReservationTourId, currentDay} = useSeller()
    const [newReservation, setNewReservation] = useState<CommonTypes.CreateReservation>({
        tour_id: createReservationTourId,
        reservation_date: currentDay as Date
    } as CommonTypes.CreateReservation)
    const [passengers, setPassengers] = useState<CommonTypes.Passenger[]>([])
    function onSelectPayment(newMethod: string) {
        const new_data = newReservation.payment_methods ? [...newReservation.payment_methods, {name: newMethod}] : [{name: newMethod}]
        setNewReservation({...newReservation, payment_methods: new_data})
    }
    useEffect(() => {
        async function retrieveList() {
            try {
                const response = await SellerService.listPassengers()
                setPassengers(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        retrieveList()
    }, [])
    async function handleSubmit () {
        try {
            const data = {
                ...newReservation,
                reservation_date: format(newReservation.reservation_date, 'yyyy-MM-dd')
            }
            await SellerService.createReservation(data)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setNewReservation({...newReservation, [name]: value})
    }

    function onChangePayment(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        const oldItem = newReservation.payment_methods.find((item: CommonTypes.PaymentMethods) => item.name === name)
        if (!oldItem) return
        const index = newReservation.payment_methods.indexOf(oldItem)
        const filteredList = [...newReservation.payment_methods.filter((item: CommonTypes.PaymentMethods) => item.name !== name)]
        const newData = {...oldItem, value}
        setNewReservation({...newReservation, payment_methods: insert(filteredList, index, newData)})
    }

    function deletePayment(name: string) {
        const newList = newReservation.payment_methods.filter((item: CommonTypes.PaymentMethods) => item.name !== name)
        setNewReservation({...newReservation, payment_methods: newList})
    } 

    return (
        <Main>
            <Modal>
                <div className="close" title="close"
                onClick={() => setCreateReservationTourId(null)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                <h3>Create a new reservation</h3>
                {
                    passengers.length > 0 &&
                    <InputDropdown
                    style={{fontSize: '14px'}}
                    options={passengers}
                    keys={['email', 'id', 'name']}
                    selector="id"
                    onChange={(newValue: string) => setNewReservation((prevData: CommonTypes.CreateReservation) => ({...prevData, passenger_id: newValue}))}
                    label="Passenger ID"
                    optionDefaultHeight={80}
                    />
                }
                <div className="calendar">
                    <Calendar
                    selectedDay={newReservation.reservation_date}
                    datesStyle={{left: '-20%'}}
                    setSelectedDay={(newDate: Date) => setNewReservation((prevData: CommonTypes.CreateReservation) => ({...prevData, reservation_date: newDate}))}
                    />
                    <span>
                        {format(newReservation.reservation_date, 'yyyy-MM-dd')}
                    </span>
                </div>
                <GeneralInput
                value={newReservation.tickets || ''}
                type="number"
                label="Ticket Price*"
                style={{marginTop: '20px'}}
                placeholder="20000"
                image={dollar}
                name="tickets"
                onChange={onChange}
                />
                <GeneralInput
                value={newReservation.price || ''}
                type="number"
                label="Sale Price*"
                style={{marginTop: '20px'}}
                placeholder="30000"
                name="price"
                image={dollar}
                onChange={onChange}
                />
                {
                    newReservation?.payment_methods?.map((method: CommonTypes.PaymentMethods, idx: number) => {
                        return (
                            <div className="payment_method" key={idx}>
                                <button
                                onClick={() => deletePayment(method.name)}
                                >
                                    <FaTrash/>
                                </button>
                                <GeneralInput
                                value={method.value || ''}
                                name={method.name}
                                type="number"
                                placeholder="Amount"
                                label={method.name}
                                image={dollar}
                                onChange={onChangePayment}
                                />
                            </div>
                        )
                    })
                }
                <Dropdown
                style={{marginTop: '20px'}}
                options={['efectivo', 'wise', 'transferencia', 'tarjeta', 'deuda'].filter(str => !newReservation?.payment_methods?.map((item: CommonTypes.PaymentMethods) => item.name).includes(str))}
                title="Payment methods"
                onSelect={onSelectPayment}
                />
                <button 
                onClick={handleSubmit}
                className="submit">Create new reservation</button>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
`

const Modal = styled.div`
width: 85%;
overflow-y: unset;
border-radius: 10px;
max-width: 500px;
position: absolute;
top: 0;
left: 50%;
max-height: 80vh;
overflow: hidden;
overflow-y: unset;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 10px 20px ${props => props.theme.blackTransparent};
padding: 20px;
animation: ${showUpModal} .3s ease forwards;
h3 {
    margin-bottom: 20px;
}
h3.payment-title {
    font-weight: 500;
    margin-bottom: 10px;
}
.payment_method {
    margin: 10px 0;
    position: relative;
    button {
        position: absolute;
        right: 0;
        top: 0;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background-color: rgba(0,0,0,0);
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid ${props => props.theme.dark};
    }
    h4 {
        font-weight: 400;
        margin-bottom: 5px;
    }
    label {
        text-transform: capitalize;
    }
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
> .calendar {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    align-items: center;
}

> button.submit {
    margin-top: 20px;
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.yellowDark};
    border: 2px solid ${props => props.theme.yellowDark};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

> button::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.yellowDark};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

> button.submit:hover {
    color: ${props => props.theme.white};
}

> button.submit:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

> button.submit:hover::before {
    top: -30px;
    left: -30%;
}
`