import { useEffect, useState } from "react";
import styled from "styled-components";
import { Zone } from "../../../types/common.types";
import ToursService from "../../../services/tours.service";
import { useAdmin } from "../../../context/admin";
import { mobile } from "../../../styles/responsive";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";
import AdminService from "../../../services/admin.service";
export default function List () {
    const { setEditZoneId } = useAdmin()
    const [list, setList] = useState<Zone[]>([])
    useEffect(() => {
        async function getList() {
            try {
                const response = (await AdminService.listZones()).data
                setList(response)
            } catch (error) {
                console.log(error)
            }
        }
        getList()
    }, [])

    async function deleteZone(id: string) {
        try {
            await AdminService.deleteZone(id)
            window.location.reload()
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Main>
            {
                list.map((item, idx: number) => {
                    return (
                        <div className="card-container">
                            <button type="button"
                            className="edit"
                            onClick={() => setEditZoneId(item.id)}
                            >
                                    <MdEdit/>
                            </button>
                            <button type="button"
                                className="delete"
                                onClick={() => deleteZone(item.id)}
                                >
                                    <FaTrash/>
                            </button>
                            <div className="card" key={idx}
                            onClick={() => {
                                // setTourInfoId(item.id)
                            }}
                            >
                                <h3 className="card__title">
                                    {item.id}
                                </h3>
                                <p className="card__content">
                                    Zone ID: {item.overview}
                                </p>
                                <div className="card__date">
                                </div>
                                <div className="card__arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="15" width="15">
                                        <path fill="#fff" d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.section`
display: grid;
grid-template-columns: repeat(2, minmax(200px, 1fr));
${mobile({
    gridTemplateColumns: '1fr'
})}
gap: 20px;
.card-container {
    position: relative;
    > button {
        z-index: 10;
        position: absolute;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        font-size: 18px;
        color: ${props => props.theme.white};
    }
    > button.edit {
        border: 2px solid ${props => props.theme.blueLight};
        background-color: ${props => props.theme.blueLight};
        right: 50px;
        top: 10px;
    }
    > button.edit:hover {
        background-color: ${props => props.theme.blue};
    }
    > button.delete {
        background-color: ${props => props.theme.lightRed};
        border: 2px solid ${props => props.theme.lightRed};
        right: 10px;
        top: 10px;
    }
}
.card {
    font-family: "Arial";
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.75rem;
    background: ${props => props.theme.whiteBackground};
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    position: relative;
    overflow: hidden;
}

.card > * + * {
    margin-top: 1.1em;
}

.card .card__content {
    color: var(--secondary-color);
    font-size: 0.86rem;
}

.card .card__title {
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.card .card__date {
    color: #6e6b80;
    font-size: 0.8rem;
}

.card .card__arrow {
    position: absolute;
    background: ${props => props.theme.blue};
    padding: 0.4rem;
    border-top-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    bottom: 0;
    right: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card svg {
    transition: 0.2s;
}

.card:hover .card__title {
    color: ${props => props.theme.blue};
    text-decoration: underline;
}

.card:hover .card__arrow {
    background: ${props => props.theme.blueDark};
}

.card:hover .card__arrow svg {
    transform: translateX(3px);
}
`