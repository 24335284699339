import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useAdmin } from "../../../context/admin";
import { useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import * as CommonTypes from '../../../types/common.types'
import { format, add } from "date-fns"
import GeneralInput from "../../common/GeneralInput";
import InputDropdown from "../../common/InputDropdown";
import Calendar from "../../common/CalendarInput";
import removeTimezone from "../../../utils/removeTimezone";
import { useGlobal } from "../../../context/global";

export default function TraspassReservation() {
    const { setMessage } = useGlobal()
    const {traspassTripReservationId, setCurrentDay, setTraspassTripTourId, setTraspassTripReservationId, currentDay, traspassTripTourId} = useAdmin()
    const [list, setList] = useState<CommonTypes.Trip[]>([])
    const [agencies, setAgencies] = useState<CommonTypes.Agency[]>([])
    const [tripSelected, setTripSelected] = useState<string>()
    const [traspassPrice, setTraspassPrice] = useState<number>()
    const [newTripData, setNewTripData] = useState<CommonTypes.TraspassReservations>({
        reservations: traspassTripReservationId,
        tour_id: traspassTripTourId,
        trip_date: currentDay
    } as CommonTypes.TraspassReservations)
    useEffect(() => {
        async function getList() {
            try {
                if (!currentDay || !traspassTripTourId) return
                const response = (await AdminService.listTripsByDayTraspass(currentDay, traspassTripTourId)).data
                setList(response)
            } catch (error) {
                console.log(error)
            }
            try {
                const response = (await AdminService.listAgencies()).data
                setAgencies(response)
            } catch (error) {
                console.log(error)
            }
        }
        getList()
    }, [currentDay, traspassTripTourId])

    async function traspass() {
        try {
            if (!tripSelected) return
            if (!traspassPrice || traspassPrice < 0) return
            const response = await AdminService.assignTrip(traspassTripReservationId, tripSelected, traspassPrice)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
            setTraspassTripReservationId([])
            setTraspassTripTourId(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    async function traspassAgency() {
        try {
            if (!newTripData.trip_date || !newTripData.agency_id || !newTripData.traspass_price) return
            const response = await AdminService.traspass(newTripData)
            setMessage({type: 'success', content: response.data})
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: 1}))
            await setCurrentDay((prevDay: Date) => add(prevDay, {days: -1}))
            setTraspassTripReservationId([])
            setTraspassTripTourId(undefined)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            <Modal>
                {
                    list.length < 1 &&
                    <>No trips upcoming from other agencies</>
                }
            {
                    list.map((trip, idx) => {
                        return (
                        <div className={`trip ${tripSelected === trip.id ? 'selected' : ''}`} key={idx}
                        onClick={() => setTripSelected(trip.id)}
                        >
                            <div className="header">
                                <span>
                                    {trip.agency.name}
                                </span>
                                <span>
                                    {trip.trip_date && removeTimezone(trip.trip_date.toString())}
                                </span>
                                <span>
                                    {trip.trip_time}
                                </span>
                                    
                            </div>
                        <TableWrapper>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Guider</th>
                                        <th>Driver</th>
                                        <th>Pickup</th>
                                        <th>Car Patent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{trip.guider?.name}</td>
                                        <td>{trip.driver?.name}</td>
                                        <td>{trip.pickup?.toString()}</td>
                                        <td>{trip.car_patent}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </TableWrapper>
                        </div>
                        )
                    })
                }
                {
                    tripSelected &&
                    <>
                    <GeneralInput
                    label="Traspass price (per reservation)*"
                    value={traspassPrice}
                    type="number"
                    onChange={e => setTraspassPrice(Number(e.target.value))}
                    style={{marginBottom: '20px'}}
                    />
                    <button type="button"
                    onClick={traspass}
                    >Traspass</button>
                    </>
                }
                <hr />
                <h3>Send to other agency</h3>
                {
                    agencies.length > 0 &&
                    <InputDropdown
                    options={agencies}
                    selector="name"
                    keys={['name']}
                    containerStyle={{margin: '20px 0'}}
                    label="Agency"
                    optionDefaultHeight={20}
                    value={newTripData.agency_id || ''}
                    onChange={(newValue: string) => setNewTripData(prevData => ({...prevData, agency_id: newValue}))}
                    />
                }
                <GeneralInput
                value={newTripData.traspass_price || ''}
                label="Traspass price"
                type="number"
                onChange={e => setNewTripData(prevData => ({...prevData, traspass_price: Number(e.target.value)}))}
                />
                <Calendar
                containerStyle={{marginTop: '20px'}}
                datesStyle={{position: 'relative'}}
                selectedDay={newTripData.trip_date}
                setSelectedDay={(newDate: Date) => setNewTripData(prevData => ({...prevData, trip_date: newDate}))}
                />
                <div
                style={{margin: '15px 0'}}
                >
                {format(newTripData.trip_date, 'dd/MM/yyyy')}
                </div>
                <button
                type="button"
                onClick={traspassAgency}
                >Send to agency</button>
                <div className="close" title="close"
                    onClick={() => {
                        setTraspassTripReservationId([])
                        setTraspassTripTourId(undefined)
                    }}
                >
                    <AiOutlineCloseCircle/>
                </div>
            </Modal>
        </Main>
    )
}

const Main = styled.div`
z-index: 100;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: ${props => props.theme.blackTransparent};
`

const showUpModal = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 50%;
    }
    `

const Modal = styled.div`
width: 80%;
max-height: 75%;
min-height: 400px;
overflow: hidden;
overflow-y: unset;
max-width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: ${props => props.theme.white};
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
padding: 20px;
padding-top: 50px;
animation: ${showUpModal} .3s ease forwards;
.date-trip {
    display: flex;
    align-items: center;
    gap: 10px;
}
h3 {
    font-size: 16px;
    font-weight: 400;
}
hr {
    margin: 20px auto;
}
.trip {
    background-color: ${props => props.theme.whiteBackground};
    border-radius: 10px;
    box-shadow: 0 3px 5px ${props => props.theme.blackTransparent};
    cursor: pointer;
    margin-bottom: 20px;
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0 0;
        position: relative;
        height: 40px;
        gap: 10px;
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
        
    }
}
.trip.selected {
    border: 1px solid ${props => props.theme.lightGreen};
    box-shadow: 0 3px 10px ${props => props.theme.lightGreenFade};
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
button[type=submit], button[type=button] {
    display: inline-block;
    padding: 0 10px;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    font-size: 17px;
    z-index: 1;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.blue};
    border: 2px solid ${props => props.theme.blue};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

button[type=submit]::before, button[type=button]::before {
    position: absolute;
    content: "";
    background: ${props => props.theme.blue};
    width: calc(100% + 100px);
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

button[type=submit]:hover, button[type=button]:hover {
    color: ${props => props.theme.white};
}

button[type=submit]:before, button[type=button]:before {
    top: 100%;
    left: 100%;
    transition: .3s all;
}

button[type=submit]:hover::before, button[type=button]:hover::before {
    top: -30px;
    left: -30%;
}
`

const TableWrapper = styled.div`
width: 100%;
height: 100%;
`

const Table = styled.table`
position: relative;
table-layout: fixed;
height: 100%;
width: 100%;
thead {
    width: 100%;
    background-color: #FFFFFF;
}
th {
    font-weight: 400;
    > button {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
            }
    }
    >button.selected-all {
        background-color: ${props => props.theme.yellowDark};
    }
}
td {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .flag {
        width: 30px;
    }
    button.select {
        width: 20px;
        height: 20px;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        line-height: 40px;
        background-color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.black};
        border-radius: 5px;
        cursor: pointer;
        transition: transform .3s ease;
        &:active {
            transform: scale(0.8);
        }
    }
    button.selected {
        background-color: ${props => props.theme.yellowDark};
    }
}
tr {
    height: 40px;
    width: 100%;
}
`