import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import GeneralInput from "../../common/GeneralInput";
import Modal from "../../common/Modal";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TourInfo, Zone } from "../../../types/common.types";
import AdminService from "../../../services/admin.service";
import includes from "../../../json/tour_includes.json"
import Select from "../../common/Select";
import Component from "../../common/Component";
import insert from "../../../utils/insert";
import jsonToFormData from "../../../utils/jsonToFormData";
import { useGlobal } from "../../../context/global";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";

export default function EditTourInfo() {
    const { setIsLoading, setMessage } = useGlobal()
    const {editTourInfoId, user, setEditTourInfoId} = useAdmin()
    const [zones, setZones] = useState<Zone[]>([])

    const [data, setData] = useState<Partial<TourInfo>>({})

    const includes_options = includes.filter(i => data.include ? !data.include.includes(i) : true).map((i) => ({name: i, value: i}))

    useEffect(() => {
        async function getInfo() {
            if (!editTourInfoId || !user) return
            try {
                const entry = {
                    tour_id: editTourInfoId,
                    agency_id: user.user.agency_id
                }
                const response = await AdminService.getTourInfo(entry)
                const {created_at, ...rest} = response.data
                setData({active: true, ...rest, ...entry})
                const response_zones = await AdminService.listZones()
                setZones(response_zones.data)
            } catch (error) {
                console.log(error)
            }
        }
        getInfo()
    }, [editTourInfoId, user])

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setData(prevData  => ({...prevData, [name]: value }))
    }
    
    function onChangeIncludes(e: ChangeEvent<HTMLSelectElement>) {
        const value = e.target.value
        const newIncludes = data.include ? [...data.include, value] : [value]
        setData(prevData => ({...prevData, include: newIncludes}))
    }
    
    function deleteIncludes(incl: string) {
        const newIncludes = data.include?.filter(i => i !== incl)
        setData(prevData => ({...prevData, include: newIncludes}))
    }
    
    function addDescription() {
        const newItem = {name: '', name_es:  '', name_pt: '', description: '', description_es: '', description_pt: ''}
        const newDesc = data.detailed_description ?  [...data.detailed_description, newItem] : [newItem]
        setData(prevData => ({...prevData, detailed_description: newDesc}))
    }
    
    function onChangeDesc(e: ChangeEvent<HTMLInputElement>, idx: number) {
        if (!data.detailed_description?.length) return
        const name = e.target.name
        const value = e.target.value
        const newItem = {...data.detailed_description[idx], [name]: value}
        const filteredList = data.detailed_description.filter((dd, indx) => indx !== idx)
        const newDesc = insert(filteredList, idx, newItem)
        setData(prevData => ({...prevData, detailed_description: newDesc}))
    }
    

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsLoading(true)
        if (!editTourInfoId) return
        try {
            const {id, zone_id ,...rest} = data
            console.log(rest);
            console.log(data);
            
            if (zone_id) await AdminService.assignZone(editTourInfoId, zone_id)
            const formData = jsonToFormData(rest)
            const response = await AdminService.handleTourInfo(formData)
            await window.location.reload()
        } catch (error: any) {
            console.log(error);
            setMessage({
                type: 'error',
                content: error.response?.data || 'Error'
            })
            setTimeout(() => {
                setMessage(undefined)
            }, 2500)
        } finally {
            setIsLoading(false)
        }
    }

    const zones_list = useMemo(() => {
        return zones.map(z => ({name: z.id, value: z.id}))
    }, [zones])
    return (
        <Modal
        onSubmit={onSubmit}
        >
            <h1>Edit tour info</h1>
            <div className="close" title="close"
                onClick={() => setEditTourInfoId(undefined)}
                >
                    <AiOutlineCloseCircle/>
                </div>
            <Select
            style={{marginBottom: '20px'}}
            label="Zone"
            defaultNull
            onChange={e => setData(prevData => ({...prevData, zone_id: e.target.value}))}
            value={data.zone_id}
            options={zones_list}
            />
            <div className="input-container">
                <Checkbox
                checked={data.active}
                label="Active"
                containerStyle={{marginBottom: '10px'}}
                onChange={(checked: boolean) => setData(prevData => ({...prevData, active: checked}))}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Overview (en)"
                value={data.overview || ''}
                name="overview"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (es)"
                value={data.overview_es || ''}
                name="overview_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (pt)"
                value={data.overview_pt || ''}
                name="overview_pt"
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Description (en)"
                value={data.description || ''}
                name="description"
                onChange={onChange}
                />
                <GeneralInput
                label="Description (es)"
                value={data.description_es || ''}
                name="description_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Description (pt)"
                value={data.description_pt || ''}
                name="description_pt"
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Cancellation policy (en)"
                value={data.cancellation_policy || ''}
                name="cancellation_policy"
                onChange={onChange}
                />
                <GeneralInput
                label="Cancellation policy (es)"
                value={data.cancellation_policy_es || ''}
                name="cancellation_policy_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Cancellation policy (pt)"
                value={data.cancellation_policy_pt || ''}
                name="cancellation_policy_pt"
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <GeneralInput
                label="Recommendations (en)"
                value={data.recommendations || ''}
                name="recommendations"
                onChange={onChange}
                />
                <GeneralInput
                label="Recommendations (es)"
                value={data.recommendations_es || ''}
                name="recommendations_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Recommendations (pt)"
                value={data.recommendations_pt || ''}
                name="recommendations_pt"
                onChange={onChange}
                />
            </div>
            <h2>Includes</h2>
            <Select
            value={''}
            defaultNull
            options={includes_options}
            onChange={onChangeIncludes}
            />
            <div className="list" 
            style={{margin: '10px 0'}}
            >
                {
                    data.include?.map((i, idx) => {
                        return (
                            <Component 
                            key={idx}
                            close={() => deleteIncludes(i)}
                            >
                                {i}
                            </Component>
                        )
                    })
                }
            </div>
            <h2>Detailed description</h2>
            <div className="actions">
                <button
                style={{float: 'initial'}}
                type="button"
                onClick={addDescription}
                >
                    +
                </button>
            </div>
            <div className="list">
                {
                    data.detailed_description?.map((dd, idx) => {
                        return (
                            <div
                            key={idx}
                            >
                                <h3>{idx + 1}.</h3>
                                <div className="input-container">
                                    <GeneralInput
                                    label="Nombre (en)"
                                    value={dd.name || ''}
                                    name="name"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                    <GeneralInput
                                    label="Nombre (es)"
                                    value={dd.name_es || ''}
                                    name="name_es"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                    <GeneralInput
                                    label="Nombre (pt)"
                                    value={dd.name_pt || ''}
                                    name="name_pt"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                </div>
                                <div className="input-container">
                                    <GeneralInput
                                    label="Description (en)"
                                    value={dd.description || ''}
                                    name="description"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                    <GeneralInput
                                    label="Description (es)"
                                    value={dd.description_es || ''}
                                    name="description_es"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                    <GeneralInput
                                    label="Description (pt)"
                                    value={dd.description_pt || ''}
                                    name="description_pt"
                                    onChange={e => onChangeDesc(e, idx)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="input-container">
                <FileInput
                value={data.photo1}
                style={{height: '100px'}}
                label="Photo 1"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo1: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo1, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
                <FileInput
                value={data.photo2}
                style={{height: '100px'}}
                label="Photo 2"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo2: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo2, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
                <FileInput
                value={data.photo3}
                style={{height: '100px'}}
                label="Photo 3"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo3: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo3, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
            </div>
            <div className="input-container">
                <FileInput
                value={data.photo4}
                style={{height: '100px'}}
                label="Photo 4"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo4: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo4, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
                <FileInput
                value={data.photo5}
                style={{height: '100px'}}
                label="Photo 5"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo5: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo5, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
                <FileInput
                value={data.photo6}
                style={{height: '100px'}}
                label="Photo 6"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, photo6: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { photo6, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
            </div>
            <div className="input-container">
                <FileInput
                value={data.cover}
                style={{height: '100px'}}
                label="Cover"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, cover: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { cover, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
            </div>
            <div className="actions"
            style={{height: '2em'}}
            >
                <button
                >
                    Editar
                </button>
            </div>
        </Modal>
    )
}