import List from "./List";
import Create from "./Create";

export default function Zones() {
    return (
        <>
        <Create/>
        <List/>
        </>
    )
}