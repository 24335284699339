import styled, { css } from "styled-components";
import { useState } from "react";
// icons
import { BsMap } from "react-icons/bs"
import { FaPersonWalkingLuggage } from 'react-icons/fa6'
import { MdOutlineSell, MdOutlineAdminPanelSettings, MdPlace} from 'react-icons/md'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { Link } from "react-router-dom";
import { TbSteeringWheel } from "react-icons/tb";
import { GiHiking } from "react-icons/gi";
import { IoTicketOutline } from "react-icons/io5";
import { IoIosAirplane } from "react-icons/io";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

// utils
import toQuery from "../../utils/toQuery";
// context
import { useAdmin } from "../../context/admin";
// superowner sidebar
import SidebarList from "./superowner/SidebarList";
import { mobile } from "../../styles/responsive";

export default function Sidebar() {
    const { tab, user } = useAdmin()
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

    return (
        <Main isSidebarOpen={isSidebarOpen}>
            <Tabs isSidebarOpen={isSidebarOpen}>
                <div className={`${tab === 'tours' && 'selected'}`}>
                    <Link to={`/admin${toQuery({tab: 'tours'})}`}>
                            <BsMap/>
                            <span>
                                Tours
                            </span>
                    </Link>
                </div>
                {
                    user?.user.source !== 'superowners' &&
                    <>
                    <div className={`${tab === 'reservations' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'reservations'})}`} >
                            <IoTicketOutline/>
                            <span>
                                Reservations
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'trips' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'trips'})}`} >
                            <IoIosAirplane/>
                            <span>
                                Trips
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'traspass' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'traspass'})}`} >
                            <FaHandsHelping/>
                            <span>
                                Traspass
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'zones' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'zones'})}`} >
                            <MdPlace/>
                            <span>
                                Zones
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'passengers' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'passengers'})}`} >
                            <FaPersonWalkingLuggage/>
                            <span>
                                Passengers
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'drivers' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'drivers'})}`} >
                            <TbSteeringWheel/>
                            <span>
                                Drivers
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'guiders' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'guiders'})}`} >
                            <GiHiking/>
                            <span>
                                Guiders
                            </span>
                        </Link>
                    </div>
                    </>
                }
                {
                    user?.user.source === 'superowners' &&
                    <SidebarList/>
                }
                {
                    user?.user.source === 'agencies' &&
                    <>
                    <div className={`${tab === 'sellers' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'sellers'})}`} >
                            <MdOutlineSell/>
                            <span>
                                Sellers
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'admins' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'admins'})}`} >
                            <MdOutlineAdminPanelSettings/>
                            <span>
                                Admins
                            </span>
                        </Link>
                    </div>
                    <div className={`${tab === 'earnings' && 'selected'}`}>
                        <Link to={`/admin${toQuery({tab: 'earnings'})}`} >
                            <RiMoneyDollarCircleLine/>
                            <span>
                                Earnings
                            </span>
                        </Link>
                    </div>
                    </>
                }
                <div className={`${tab === 'me' && 'selected'}`}>
                    <Link to={`/admin${toQuery({tab: 'me'})}`}>
                        <IoPersonCircleOutline/>
                        <span>
                            My profile
                        </span>
                    </Link>
                </div>
            </Tabs>
            <Close isSidebarOpen={isSidebarOpen}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                {
                    isSidebarOpen ?
                    <IoIosArrowBack/>
                    :
                    <IoIosArrowForward/>
                }
            </Close>
        </Main>
    )
}


const Main = styled.aside<{isSidebarOpen: boolean}>`
${props => props.isSidebarOpen && 
mobile({
    position: "absolute",
    zIndex: 15
})
}
padding: 20px;
background-color: ${props => props.theme.dark};
height: 100%;
width: fit-content;
position: relative;
padding-top: 50px;
`

const Tabs = styled.div<{isSidebarOpen:boolean}>`
overflow: hidden;
${props => props.isSidebarOpen && 
    mobile({
        marginRight: '10px'
    })
}
${props => !props.isSidebarOpen && 
    mobile({
        width: 0
    })
}
.selected {
    a {
        background-color: ${props => props.theme.black};
    }
}
 a {
    color: ${props => props.theme.white};
    margin-bottom: 5px;
    padding: 5px 10px;
    transition: background .3s linear;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.black};
    }
    &:current {
        background-color: red;
    }
}
`

const Close = styled.div<{isSidebarOpen:boolean}>`
cursor: pointer;
display: none;
${mobile({
    display: 'flex'
})}
position: absolute;
top: 0;
right: 0;
width: 20px;
height: 100%;
background-color: ${props => props.theme.dark};
color: ${props => props.theme.white};
overflow: hidden;
justify-content: center;
align-items: center;
/* ${props => props.isSidebarOpen && css`
`} */
`