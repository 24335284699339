import styled from "styled-components";
import { useSeller } from "../../context/seller";
import { format } from "date-fns";

export default function CurrentDay() {
    const {currentDay} = useSeller()
    return (
        <Main>
            { currentDay && format(currentDay, 'dd/MM/yyyy')}
        </Main>
    )
}

const Main = styled.div`
height: 40px;
width: 100%;
border-radius: 10px 10px 0 0;
display: flex;
align-items: center;
justify-content: center;
background-color: ${props => props.theme.black};
color: ${props => props.theme.white};
`