import styled from "styled-components"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useEffect } from "react"
// context
import { useAdmin } from "../../context/admin"
// components
import Navbar from "../../components/admin/Navbar"
import background from '../../assets/admin/background.jpg'
import Sidebar from "../../components/admin/Sidebar"
// utils
import toQuery from "../../utils/toQuery"
// modals
import CreateTour from "../../components/admin/modals/CreateTour"
import TourInfo from "../../components/admin/modals/TourInfo"
import CreatePassenger from "../../components/admin/modals/CreatePassenger"
import CreateAgency from "../../components/admin/modals/CreateAgency"
import CreateSeller from "../../components/admin/modals/CreateSeller"
import CreateAdmin from "../../components/admin/modals/CreateAdmin"
import PassengerPlan from "../../components/admin/modals/PassengerPlan"
import CreateGuider from "../../components/admin/modals/CreateGuider"
import CreateDriver from "../../components/admin/modals/CreateDriver"
import EditTrip from "../../components/admin/modals/EditTrip"
import AgencyRelationship from "../../components/admin/modals/AgencyRelationship"
import EditReservation from "../../components/admin/modals/EditReservation"
import EditPassenger from "../../components/admin/modals/EditPassenger"
import AssignRservation from "../../components/admin/modals/AssignReservation"
import TraspassReservation from "../../components/admin/modals/TraspassReservation"
import ReadExcel from "../../components/admin/modals/ReadExcel"
import RegisterCosts from "../../components/admin/modals/RegisterCosts"
import Requirements from "../../components/admin/modals/Requirements"
import Accounting from "../../components/admin/modals/Accounting"
import AddPayment from "../../components/admin/modals/AddPayment"
import EditTour from "../../components/admin/modals/EditTour"
// tabs
import Passengers from "../../components/admin/Passengers"
import Tours from "../../components/admin/Tours"
import Agencies from "../../components/admin/superowner/Agencies"
import Sellers from "../../components/admin/Sellers"
import Admins from "../../components/admin/Admins"
import Me from "../../components/admin/Me"
import Guiders from "../../components/admin/Guiders"
import Drivers from "../../components/admin/Drivers"
import Reservations from "../../components/admin/Reservations"
import Trips from "../../components/admin/Trips"
import Traspass from "../../components/admin/Traspass"
import EditDriver from "../../components/admin/modals/EditDriver"
import EditGuider from "../../components/admin/modals/EditGuider"
import EditSeller from "../../components/admin/modals/EditSeller"
import EditAdmin from "../../components/admin/modals/EditAdmin"
import EditAgency from "../../components/admin/modals/EditAgency"
import Earnings from "../../components/admin/Earnings"
import ReservationsDetails from "../../components/admin/modals/ReservationsDetails"
import ViewPayments from "../../components/admin/modals/ViewPayments"
import EditTourInfo from "../../components/admin/modals/EditTourInfo"
import PostBlog from "../../components/admin/modals/PostBlog"
import Zones from "../../components/admin/Zones"
import CreateZone from "../../components/admin/modals/CreateZone"
import EditZone from "../../components/admin/modals/EditZone"

export default function Home() {
    const { 
        tab, 
        setTab, 
        isCreateTourModalOpen, 
        isCreatePassengerModalOpen, 
        isCreateSellerModalOpen,
        isCreateAdminModalOpen,
        isCreateAgencyModalOpen,
        isCreateGuiderModalOpen,
        isCreateDriverModalOpen,
        tourInfoId, 
        passengerPlanId,
        traspassTripReservationId,
        assignTripReservationId,
        registerPassengersTripId,
        registerCostsTripId,
        requirementsTripId,
        accountingSellerId,
        editTripId,
        relationshipAgencyId,
        editReservationId,
        editPassengerData,
        editTourData,
        editDriverData,
        editGuiderData,
        editSellerData,
        editAdminData,
        editAgencyData,
        addPaymentData,
        reservationDetails,
        viewPaymentAgencyId,
        editTourInfoId,
        createBlogTourId,
        isCreateZoneModalOpen,
        editZoneId,
        user,
    } = useAdmin()
    const router = useNavigate()
    useEffect(() => {
        if (!user) router('/admin/access')
    },  [user, router])
    const searchParams = useSearchParams()
    useEffect(() => {
        if (!user) return
        const currentTab = searchParams[0].get('tab')
        if (!currentTab) {
            router(`/admin${toQuery({tab: 'tours'})}`)
            setTab('tours')
        } else setTab(currentTab)
    }, [router, searchParams, setTab])
    return (
        <Main>
            <Modal>
                <Sidebar/>
                <Content>
                    <Navbar/>
                    {
                        tab === 'tours' &&
                        <Tours/>
                    }
                    {
                        tab === 'passengers' &&
                        <Passengers/>
                    }
                    {
                        tab === 'agencies' &&
                        <Agencies/>
                    }
                    {
                        tab === 'sellers' &&
                        <Sellers/>
                    }
                    {
                        tab === 'admins' &&
                        <Admins/>
                    }
                    {
                        tab === 'me' &&
                        <Me/>
                    }
                    {
                        tab === 'guiders' &&
                        <Guiders/>
                    }
                    {
                        tab === 'drivers' &&
                        <Drivers/>
                    }
                    {
                        tab === 'reservations' &&
                        <Reservations/>
                    }
                    {
                        tab === 'trips' &&
                        <Trips/>
                    }
                    {
                        tab === 'traspass' &&
                        <Traspass/>
                    }
                    {
                        tab === 'earnings' &&
                        <Earnings/>
                    }
                    {
                        tab === 'zones' &&
                        <Zones/>
                    }
                </Content>
            </Modal>
            {
                isCreateTourModalOpen &&
                <CreateTour/>
            }
            {
                tourInfoId &&
                <TourInfo/>
            }
            {
                isCreatePassengerModalOpen &&
                <CreatePassenger/>
            }
            {
                isCreateAgencyModalOpen &&
                <CreateAgency/>
            }
            {
                isCreateSellerModalOpen &&
                <CreateSeller/>
            }
            {
                isCreateAdminModalOpen &&
                <CreateAdmin/>
            }
            {
                isCreateGuiderModalOpen &&
                <CreateGuider/>
            }
            {
                isCreateDriverModalOpen &&
                <CreateDriver/>
            }
            {
                passengerPlanId &&
                <PassengerPlan/>
            }
            {
                assignTripReservationId &&
                assignTripReservationId.length > 0 &&
                <AssignRservation/>
            }
            {
                traspassTripReservationId &&
                traspassTripReservationId.length > 0 &&
                <TraspassReservation/>
            }
            {
                registerPassengersTripId &&
                <ReadExcel/>
            }
            {
                registerCostsTripId &&
                <RegisterCosts/>
            }
            {
                requirementsTripId &&
                <Requirements/>
            }
            {
                accountingSellerId &&
                <Accounting/>
            }
            {
                editTripId &&
                <EditTrip/>
            }
            {
                relationshipAgencyId &&
                <AgencyRelationship/>
            }
            {
                editReservationId &&
                <EditReservation/>
            }
            {
                editPassengerData && 
                <EditPassenger/>
            }
            {
                editTourData &&
                <EditTour/>
            }
            {
                editDriverData &&
                <EditDriver/>
            }
            {
                editGuiderData &&
                <EditGuider/>
            }
            {
                editSellerData &&
                <EditSeller/>
            }
            {
                editAdminData &&
                <EditAdmin/>
            }
            {
                editAgencyData &&
                <EditAgency/>
            }
            {
                addPaymentData &&
                <AddPayment/>
            }
            {
                reservationDetails &&
                <ReservationsDetails/>
            }
            {
                viewPaymentAgencyId &&
                <ViewPayments/>
            }
            {
                editTourInfoId &&
                <EditTourInfo/>
            }
            {
                createBlogTourId &&
                <PostBlog/>
            }
            {
                isCreateZoneModalOpen &&
                <CreateZone/>
            }
            {
                editZoneId &&
                <EditZone/>
            }
        </Main>
    )
}

const Main = styled.main`
background: url(${background});
background-size: cover;
width: 100vw;
height: 100dvh;
position: relative;
`

const Modal = styled.div`
height: 80%;
width: 90%;
max-width: 1300px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
box-shadow: 0 0 20px ${props => props.theme.blackTransparent};
background-color: ${props => props.theme.white};
display: flex;
`

const Content = styled.div`
flex: 1;
padding: 20px;
overflow: hidden;
overflow-y: unset;
`