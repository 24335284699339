import { useEffect, useState } from "react";
import styled from "styled-components";
import { Guider } from "../../../types/common.types";
import { format } from "date-fns";
import AdminService from "../../../services/admin.service";
import { mobile } from "../../../styles/responsive";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";
import { useAdmin } from "../../../context/admin";

export default function List() {
    const {setEditDriverData} = useAdmin()
    const [list, setList] = useState<Guider[]>([])
    useEffect(() => {
        async function getSellers() {
            try {
                const response = await (await AdminService.listDrivers()).data
                console.log(response)
                setList(response)
            } catch (error) {
                console.log(error)
            }
        }
        getSellers()
    }, [])

    async function deleteDriver(id:string) {
        try {
            await AdminService.deleteDriver(id)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Main>
            {
                list.map((item, idx: number) => {
                    return (
                        <div className="card-container">
                            <button type="button"
                                className="edit"
                                onClick={() => setEditDriverData(item)}
                                >
                                    <MdEdit/>
                            </button>
                            <button type="button"
                                className="delete"
                                onClick={() => deleteDriver(item.id)}
                                >
                                    <FaTrash/>
                            </button>
                            <div className="card" key={idx}
                            >
                                <h3 className="card__title">
                                    {item.name}
                                </h3>
                                <div className="card__content">
                                    Id: {item.id}
                                    <br />
                                    Email: {item.email}     
                                    <br />
                                    Phone: {item.phone}
                                    <br />
                                    RUT / Passport: {item.rut_passport}
                                </div>
                                <div className="card__date">
                                    {format(new Date(item.created_at), 'yyyy MMM dd H:mm')}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Main>
    )
}

const Main = styled.div`
display: grid;
grid-template-columns: repeat(2, minmax(200px, 1fr));
${mobile({
    gridTemplateColumns: '1fr'
})}
gap: 20px;
hr {
    margin: 10px 0;
    border-top: 1px solid ${props => props.theme.blackTransparent};
}
.card-container {
    position: relative;
    > button {
        z-index: 10;
        position: absolute;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        font-size: 18px;
        color: ${props => props.theme.white};
    }
    > button.edit {
        border: 2px solid ${props => props.theme.blueLight};
        background-color: ${props => props.theme.blueLight};
        right: 50px;
        top: 10px;
    }
    > button.edit:hover {
        background-color: ${props => props.theme.blue};
    }
    > button.delete {
        background-color: ${props => props.theme.lightRed};
        border: 2px solid ${props => props.theme.lightRed};
        right: 10px;
        top: 10px;
    }
}
.card {
    --border-radius: 0.75rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    background: ${props => props.theme.whiteBackground};
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    transition: transform .3s ease;
    cursor: pointer;
}

.card .card__content {
    color: ${props => props.theme.dark};
    font-size: 0.86rem;
    line-height: 20px;
    span.by-admin {
        font-size: 12px;
        color: ${props => props.theme.gray};
    }
}

.card .card__title {
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.card .card__date {
    color: #6e6b80;
    font-size: 0.8rem;
}
`