import { ChangeEvent, FormEvent, useState } from "react";
import GeneralInput from "../../common/GeneralInput";
import Modal from "../../common/Modal";
import { Zone } from "../../../types/common.types";
import { useAdmin } from "../../../context/admin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AdminService from "../../../services/admin.service";
import Checkbox from "../../common/Checkbox";
import FileInput from "../../common/FileInput";
import jsonToFormData from "../../../utils/jsonToFormData";

export default function CreateZone() {
    const { setIsCreateZoneModalOpen } = useAdmin()
    const [data, setData] = useState<Partial<Zone>>({
        id: '',
        navbar: false,
    } as Zone)

    async function submit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        try {
            const formData = jsonToFormData(data)
            await AdminService.registerZone(formData)
            window.location.reload()
        } catch (error) {
            console.log(error);
        }
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const name = e.target.name
        setData(prevData  => ({...prevData, [name]: value }))
    }

    return  (
        <Modal
        onSubmit={submit}
        >
            <h1>Create zone</h1>
            <div className="input-container">
                <Checkbox
                checked={data.navbar}
                label="Show in navbar"
                onChange={(checked: boolean) => setData(prevData => ({...prevData, navbar: checked}))}
                />
            </div>
            <div className="input-container"
            
            >
                <GeneralInput
                name="id"
                label="ID"
                value={data.id}
                onChange={onChange}
                />
            </div>
            <div className="input-container">
                <FileInput
                value={data.cover}
                style={{height: '100px'}}
                label="Cover"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={(newFile: File) => setData(prevData => ({...prevData, cover: newFile}))}
                deleteFile={() => setData(prevData => {
                    const { cover, ...rest } = prevData; // Destructure to exclude 'photo'
                    return rest; // Return the rest of the properties without 'photo'
                })}
                />
            </div>
            <div className="input-container">
            <div className="input-container">
                <GeneralInput
                label="Overview (en)"
                value={data.overview || ''}
                name="overview"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (es)"
                value={data.overview_es || ''}
                name="overview_es"
                onChange={onChange}
                />
                <GeneralInput
                label="Overview (pt)"
                value={data.overview_pt || ''}
                name="overview_pt"
                onChange={onChange}
                />
            </div>
            </div>
            <div className="actions"
            style={{marginTop: '20px'}}
            >
                <button type="submit">Submit</button>
            </div>
            <div className="close" title="close"
            onClick={() => setIsCreateZoneModalOpen(false)}
            >
                <AiOutlineCloseCircle/>
            </div>
        </Modal>
    )
}